/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import FileDownload from 'js-file-download'
import {
  Input,
  Message,
  Button,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Label,
  Table,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { getContactsRequest } from '~/store/modules/contatos/actions'
import { ContainerSemantic } from './styles'

export default function Contatos() {
  const dispatch = useDispatch()
  const contatos = useSelector((state) => state.contatos.contacts)
  const profile = useSelector((state) => state.user.profile)
  const [ident, setIdent] = useState('')
  const [did, setDid] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)

  const handleExport = useCallback(() => {
    setDownloadLoading(true)

    api
      .get('/export/contacts', {
        params: {
          fk_id_dominio: profile.id_dominio,
        },
        responseType: 'blob',
      })
      .then((response) => {
        setDownloadLoading(false)
        FileDownload(response.data, 'contatos.xlsx')
      })
      .catch((error) => {
        console.log(error)
        toast.error('Erro ao exportar contatos')
        setDownloadLoading(false)
      })
  }, [profile.id_dominio])

  useEffect(() => {
    dispatch(getContactsRequest({ ident, did }))
  }, [did, dispatch, ident])

  return (
    <ContainerSemantic>
      <Message>
        <Input
          id="did"
          type="text"
          value={did}
          placeholder="DID"
          onChange={(e) => setDid(e.target.value)}
        />
        <Input
          id="identificacao"
          type="text"
          value={ident}
          placeholder="Identificação"
          onChange={(e) => setIdent(e.target.value)}
        />
        <Link to="/contatos/add">
          <Button fluid primary>
            Adicionar
          </Button>
        </Link>
        <Link to="/contatos/template">
          <Button fluid color="green">
            Template
          </Button>
        </Link>
        <Button
          loading={downloadLoading}
          type="button"
          fluid
          color="yellow"
          onClick={handleExport}
        >
          Exportar
        </Button>
      </Message>
      <div className="ui cards">
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                #
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Numero
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Nome
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Fraseologia
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {contatos.rows?.map((contato) => {
              return (
                <TableRow key={contato.id}>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    <Link to={`/contato/${contato.id}`}>{contato.id}</Link>
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {contato.ContactNumbers.map((number) => {
                      return (
                        <Label key={number.id} horizontal color="teal">
                          {number.numero}
                        </Label>
                      )
                    })}
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {contato.descricao}
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {contato.fraseologia}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </ContainerSemantic>
  )
}
